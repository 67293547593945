@import "~bootstrap/scss/bootstrap";
@import "./../basedata/variables";

.proccess-card {
  transition: all ease 2s; }

.instruction-list {
  border: 0 !important;

  li {
    display: flex;
    padding: 0;
    margin: 16px 0;
    @include fontDefault;
    list-style: none;
    border: 0;
    color: var(--pm-dark);
    font-size: 1em;
    line-height: 1.3em;

    i {
      color: var(--pm-primary);
      margin-right: 8px;
      line-height: 20px;
      font-size: 1.3em;
      margin-top: 3px; } }

  &.success {
    min-height: 300px;
    text-align: center;
    background-color: #2c661f;
    color: #ffffff; }
  animation: {}

  h1 {
    span {
      opacity: 0;
      &::before {
        border-color: #ffffff; } } }

  h2 {
    opacity: 0;
    animation: appearing 1s ease forwards; }
  p {
    opacity: 0;
    animation: appearing 1s ease forwards;
    animation-delay: 0.5s;

    &.loading {
      min-height: 300px;
      text-align: center;
      background-color: #2c661f;
      color: #ffffff;
      justify-content: center; } }

  h1 {
    display: none; }

  &.error {
    min-height: 300px;
    text-align: center;
    background-color: #1345a2;
    color: #ffffff; }

  p span {
    color: #81d742; } }

@keyframes appearing {
  0% {
    opacity: 0; }

  100% {
    opacity: 100; } }

.medium-frame {
  min-height: 400px; }
